import { articlesHeaders } from "../../data/tableHeaders/articles";
import { GetArticlesList } from "../../components/compounds/compoundList";
import { searchParams } from "../../data/search/articles";

import { ExpandableDataTable } from "../../components/globals/dataTable";
import { subContentHeaders } from "../../data/tableHeaders/subContent/articles";

const Articulos = () => {
    // Parámetros de Configuración para cargar los datos requeridos
    const articlesConfig = {
        id: 1,
        title: "Articulos",
        module: "articles",
        tableHeader: articlesHeaders,
        description: "Listado de Articulos registrado en el sistema",
        endpoint: "datos/articulosCompletos",
        contentList: "obtener/articulosCompuestos"
    };
	// ----------------------------------------------------------------------------------------


	// No Tocar este bloque de código
	// ========================================================================================
    return (
        <>
            <GetArticlesList data={articlesConfig} />
        </>
    );
	// ========================================================================================
};

export default Articulos;