const calculatePrecioVenta = (precio_compra, precio_venta, porcentajeGlobal = 0) => {
    const formatCurrency = (value) => {
        return new Intl.NumberFormat('es-MX', { style: 'currency', currency: 'MXN' }).format(value);
    };

    // Validaciones para garantizar que los valores son números válidos
    precio_compra = parseFloat(precio_compra) || 0; // Si no es un número válido, se asigna 0
    precio_venta = parseFloat(precio_venta) || 0;
    porcentajeGlobal = parseFloat(porcentajeGlobal) || 0;

    let precioVentaValor = 0;

    if (precio_venta === 0) {
        // Caso: No hay precio de venta, se calcula con porcentajeGlobal
        precioVentaValor = precio_compra * (1 + porcentajeGlobal / 100);
    } else if (precio_venta > 1) {
        // Caso: Precio de venta proporcionado es mayor que 1
        precioVentaValor = precio_venta;
    } else if (precio_venta > 0 && precio_venta < 1) {
        // Caso: Precio de venta como porcentaje de descuento
        precioVentaValor = precio_compra * (1 - precio_venta);
    } else {
        // Caso: Valor no válido
        return {
            precioVentaValor: 0,
            precioVentaFormatted: formatCurrency(0),
            isPrecioVentaMenor: false
        };
    }

    // Formatear el valor final de precio de venta
    const precioVentaFormatted = formatCurrency(precioVentaValor);

    return {
        precioVentaValor,
        precioVentaFormatted,
        isPrecioVentaMenor: precioVentaValor < precio_compra
    };
};

export default calculatePrecioVenta;
