


export const projectList = [
	{
		"field": "nombre",
		"headerName": "Nombre del Proyecto",
		"headerAlign": "center",
		"align": "center",
		"flex": 1
	},
	{
		"field": "descripcion",
		"headerName": "Descripción",
		"headerAlign": "center",
		"align": "center",
		"flex": 1
	},
	{
		"field": "total",
		"headerName": "Costo Total",
		"flex": 1,
		"headerAlign": "center",
		"align": "center",
		"flex": 1
	},
	{
		"field": "fechaCreacion",
		"headerName": "Fecha de Creación",
		"headerAlign": "center",
		"align": "center",
		"currency": 1,
		"width": 100
	},
	{
		"field": "fechaEdicion",
		"headerName": "Fecha de Edición",
		"headerAlign": "center",
		"align": "center",
		"currency": 1,
		"width": 100
	},
	{
		"field": "status",
		"headerName": "Estado",
		"headerAlign": "center",
		"align": "center",
		"currency": 1,
		"width": 100
	},
	{
		"field": "acciones",
		"headerName": "Acciones",
		"flex": 1,
		"headerAlign": "center",
		"align": "center",
		"flex": 1,
		"botons": {
			"editar": {
				"label": "Ver Proyecto",
				"class": "info",
				"type": "link",
				"action": "details/${row.id}"
			}
		}
	}
];

export const proyectDetails = [
	{
		"field": "clave",
		"headerName": "Categoría / Clave Artículo",
		"headerAlign": "center",
		"align": "center",
		"width": 400
	},
	{
		"field": "cantidad",
		"headerName": "Cantidad",
		"headerAlign": "center",
		"detalle": "",
		"align": "center",
		"width": 50
	},
	{
		"field": "descCorta",
		"headerName": "Descripción",
		"headerAlign": "center",
		"detalle": "",
		"align": "center",
		"width": 100
	},
	{
		"field": "familia",
		"headerName": "Familia",
		"headerAlign": "center",
		"detalle": "",
		"align": "center",
		"width": 100
	},
	{
		"field": "unidad",
		"headerName": "Unidad",
		"headerAlign": "center",
		"detalle": "",
		"align": "center",
		"width": 100
	},
	{
		"field": "precio",
		"headerName": "Precio de Venta",
		"headerAlign": "center",
		"align": "center",
		"currency": 1,
		"width": 100
	},
	{
		"field": "acciones",
		"headerName": "Acciones",
		"flex": 1,
		"headerAlign": "center",
		"align": "center",
		"width": 100
	}
];
