import React, { useState, useEffect } from 'react';
import { Link, useLocation, useParams, useNavigate } from 'react-router-dom';
import { ExpandLess, ExpandMore, Save, Cancel } from '@mui/icons-material';

import { Grid, Box, useTheme, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Collapse, IconButton, Button, Modal, Switch, Typography, TextField } from '@mui/material';

import { useSnackbar } from 'notistack';

import { DataGrid } from "@mui/x-data-grid";
import { styled } from '@mui/material/styles';

import { tokens } from "../../data/tokens";
import { Header } from "../basics/Header";
import { UseAppConfig } from '../../system/config';
import { sendPostData, getPostData, getDataArray } from '../../system/getData';

import ConfirmationDialog from "../globals/confirmation";
import SearchFilters from "../globals/searchFilters";
import calculatePrecioVenta from "../globals/sellPrice";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBox, faCopy, faPlus, faEdit, faTrash } from '@fortawesome/free-solid-svg-icons';

import { FormRcd } from "../catalogs/addCatalogs";

import { CreateSection, EditSection, AddArticles, CreateProject } from "../../components/projects/addElements";

import '../../theme/project.css';

const formatCurrency = (value) => {
    return new Intl.NumberFormat('es-MX', { style: 'currency', currency: 'MXN' }).format(value);
};

export const JSONDataTable = ({ data }) => {
    const navigate = useNavigate();
    const handleEdit = (field) => {
        navigate(`/${data.module}/edit/${field}`);
    };
    return (
        <Grid p={4} container justifyContent="center" className="row-printable">
            <Grid item xs={12} md={12}>
                <Header title={data.title} subtitle={data.description} />
                <Paper elevation={3} className="panel">
                    <Box mt={4}>
                        <TableContainer component={Paper}>
                            <Table>
                                <TableHead>
                                    <TableRow>
                                        {data.tableHeader.map((item, index) => (
                                            <TableCell
                                                key={index}
                                                sx={{ backgroundColor: '#052c65', color: 'white' }}
                                            >
                                                {item.headerName}
                                            </TableCell>
                                        ))}
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {data.tableContent.map((item, index) => (
                                        <TableRow key={index}>
                                            <TableCell
                                                sx={{ backgroundColor: index % 2 === 0 ? '#f1f2f3' : '#ffffff' }}
                                            >
                                                {item.name}
                                            </TableCell>
                                            <TableCell
                                                align="left"
                                                sx={{ backgroundColor: index % 2 === 0 ? '#f1f2f3' : '#ffffff' }}
                                            >
                                                {item.description || 'Sin descripción'}
                                            </TableCell>
                                            <TableCell
                                                align="center"
                                                sx={{ backgroundColor: index % 2 === 0 ? '#f1f2f3' : '#ffffff' }}
                                            >
                                                <Button
                                                    variant="contained"
                                                    color="warning"
                                                    size="small"
                                                    sx={{ mr: 1 }}
                                                    onClick={() => handleEdit(item.field)}
                                                >
                                                    Editar
                                                </Button>
                                            </TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Box>
                </Paper>
            </Grid>
        </Grid>
    );
};

export const SimpleDataTable = ({ data }) => {
    const { config, configData } = UseAppConfig();
    const [dataTable, setDataTable] = useState([]);

    const fetchDataTable = async (params = {}) => {
        try {
            const jsonData = await getPostData(`${config.API_BASE_URL}/${data.endpoint}`, { params });

            let formattedData;
            const formatNumber = data.tableHeader.some(rcd => rcd.currency == 1);

            formattedData = jsonData.map(row => ({
                ...row,
                statusValue: row.status,
                status: row.status === 1 ? 'Activado' : 'Desactivado',
                total: formatCurrency(row.total)
            }));

            setDataTable(formattedData);
            console.log(formattedData);
        } catch (error) {
            console.error('Se ha generado un error a la hora de intentar obtener los datos:', error);
        }
    };

    useEffect(() => {
        const savedFilters = localStorage.getItem('searchFilters');
        if (savedFilters) {
            const parsedFilters = JSON.parse(savedFilters);
            fetchDataTable(parsedFilters);
        } else {
            fetchDataTable();
        }
    }, [data.endpoint]);

    return (
        <Grid p={4} container justifyContent="center" className="row-printable">
            <Grid item xs={12} md={12}>
                <Header title={data.title} subtitle={data.description} />
                <Paper elevation={3} className="panel">
                    <Box mt={4}>
                        <TableContainer component={Paper}>
                            <Table>
                                <TableHead>
                                    <TableRow>
                                        {data.tableHeader.map((header, index) => (
                                            <TableCell
                                                key={index}
                                                sx={{
                                                    backgroundColor: '#052c65',
                                                    color: 'white',
                                                    textAlign: header.field === 'total' ? 'right' : (header.align || 'center'),
                                                    flex: header.flex || 1,
                                                }}
                                            >
                                                {header.headerName}
                                            </TableCell>
                                        ))}
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {dataTable.map((row, rowIndex) => (
                                        <TableRow key={rowIndex}>
                                            {data.tableHeader.map((header, cellIndex) => (
                                                <TableCell
                                                    key={cellIndex}
                                                    sx={{
                                                        backgroundColor: rowIndex % 2 === 0 ? '#f1f2f3' : '#ffffff',
                                                        textAlign: header.field === 'total' ? 'right' : (header.align || 'center'),
                                                        flex: header.flex || 1,
                                                    }}
                                                >
                                                    {header.field === 'acciones' ? (
                                                        Object.keys(header.botons).map((buttonKey) => {
                                                            const button = header.botons[buttonKey];
                                                            return (
                                                                <Button
                                                                    key={buttonKey}
                                                                    variant="contained"
                                                                    color={button.class}
                                                                    size="small"
                                                                    sx={{ mr: 1 }}
                                                                    onClick={() => {
                                                                        if (button.type === 'link') {
                                                                            window.location.href = data.module + '/' + button.action.replace('${row.id}', row.id);
                                                                        }
                                                                    }}
                                                                >
                                                                    {button.label}
                                                                </Button>
                                                            );
                                                        })
                                                    ) : (
                                                        row[header.field]
                                                    )}
                                                </TableCell>
                                            ))}
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Box>
                </Paper>
            </Grid>
        </Grid>
    );
};

export const DataTablePerID = ({ data, section }) => {
    const { id } = useParams();
    const { config } = UseAppConfig();
    const [dataTable, setDataTable] = useState({});

    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState(null);

    const [modalOpen, setModalOpen] = useState(false);
    const [modalSectionName, setModalSectionName] = useState('');
    const [modalSectionValue, setModalSectionValue] = useState('');
    const [modalSection, setModalSection] = useState(false);

    const handleModalOpen = (catalogValue, catalogName, section) => {
        setModalSectionValue(catalogValue);
        setModalSectionName(catalogName);
        setModalSection(section);
        setModalOpen(true);
    };

    const handleModalClose = () => {
        setModalOpen(false);
    };

    const getColumns = (catalog) => {
        if (!catalog || !catalog.columns) return [];
        const columns = [
            ...(catalog.columns || []),
            {
                field: 'edit',
                headerName: 'Editar',
                width: 100,
                headerAlign: 'center',
                align: 'center',
                renderCell: (params) => (
                    <Box display="flex" justifyContent="center" width="100%">
                        <Button
                            style={{ width: '10px', minWidth: '10px' }}
                            variant="contained"
                            color="warning"
                            onClick={() => handleModalOpen(id, params.row.id, 'edit')}
                        >
                            <FontAwesomeIcon icon={faEdit} />
                        </Button>
                    </Box>
                ),
            },
        ];
        return columns;
    };

    const fetchDataTable = async (field) => {
        try {
            const jsonData = await getPostData(`${config.API_BASE_URL}/${data.endpoint}/${field}`, {});

            let formattedData = jsonData.map(row => ({
                ...row,
                status: row.status === 1 ? 'Activo' : 'Desactivado',
                module: row.tipo === 'Simple' ? 'articles' : 'compounds'
            }));

            setDataTable(prevState => ({ ...prevState, [field]: formattedData }));
        } catch (error) {
            console.error('Error al obtener los datos:', error);
            setError(`No ha sido posible obtener los datos del registro: ${field}`);
        }
    };

    useEffect(() => {
        const fetchData = async () => {
            if (id) {
                setIsLoading(true);
                await fetchDataTable(id);
                setIsLoading(false);
            }
        };
        fetchData();
    }, [id]);

    if (isLoading) {
        return <div>Cargando...</div>;
    }

    if (error) {
        return <div>{error}</div>;
    }

    return (
        <Box m="0px">
            <Box
                maxWidth="100%"
                mx="auto"
                mt="-25px"
                sx={{
                    "& .MuiTable-root": { border: "none" },
                    "& .MuiTableCell-root": { borderBottom: "none" },
                    "& .MuiTableHead-root": { backgroundColor: colors.primary[500], borderBottom: "none" },
                    "& .MuiTableCell-head": { color: colors.grey[900] },
                    "& .MuiTableContainer-root": { backgroundColor: colors.primary[900] },
                }}
            >
                <Box display="flex" justifyContent="space-between" alignItems="center" mb={2}>
                    <Typography variant="h6">Registros del Catálogo</Typography>
                    <Button
                        variant="contained"
                        color="info"
                        onClick={() => handleModalOpen(id, section.name, 'new')}
                    >
                        <FontAwesomeIcon icon={faPlus} /> Agregar
                    </Button>
                </Box>
                <DataGrid
                    rows={dataTable[id] || []}
                    columns={getColumns(section) || []}
                    getRowClassName={(params) =>
                        params.indexRelativeToCurrentPage % 2 === 0 ? 'fila-par' : 'fila-impar'
                    }
                    disableColumnMenu
                    disableColumnSelector
                    hideFooter
                    className="datos-tabla"
                    autoHeight
                    sx={{
                        '& .MuiDataGrid-columnHeaders': {
                            backgroundColor: '#052c65',
                            color: '#fff',
                        }
                    }}
                />
            </Box>
            <Modal
                open={modalOpen}
                onClose={handleModalClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                }}
            >
                <Paper
                    elevation={3}
                    sx={{
                        width: '100%',
                        maxWidth: '700px',
                        margin: '0 auto',
                        padding: 2,
                    }}
                >
                    {modalSection === 'new' ? (
                        <FormRcd secValue={modalSectionValue} secName={modalSectionName} />
                    ) : (
                        <FormRcd secValue={modalSectionValue} regID={modalSectionName} />
                    )}
                </Paper>
            </Modal>
        </Box>
    );
};

export const IconsDefault = ({ tipo, icon = '', data }) => {
    const { config } = UseAppConfig();
    const [iconSrc, setIconSrc] = useState('');

    useEffect(() => {
        const fetchIcons = async () => {
            try {
                const response = await getDataArray(`${config.API_BASE_URL}/catalogo/iconos`);
                console.log("Respuesta completa de la API:", response);

                const iconCatalog = response[0];
                console.log("Catálogo de íconos extraído:", iconCatalog);

                const defaultIcon = iconCatalog.find((item) => item.seccion.toLowerCase() === tipo.toLowerCase());
                console.log("Ícono predefinido encontrado:", defaultIcon);

                if (defaultIcon && defaultIcon.imagen) {
                    setIconSrc(`/assets/icons/${defaultIcon.imagen}`);
                } else {
                    console.warn("No se encontró un ícono válido. Asignando uno genérico.");
                    setIconSrc('/assets/icons/default.png');
                }
            } catch (error) {
                console.error('Error al cargar los íconos desde la API:', error);
            }
        };

        fetchIcons();
    }, [tipo, icon, config, data]);

    return (
        <TableCell sx={{ textAlign: 'center', width: '7%' }}>
            <img src={iconSrc} width="20px" alt={tipo} />
        </TableCell>
    );
};

export const ExpandableDataTable = ({ data, onTotalCalculated }) => {
    const { config, configData } = UseAppConfig();
    const [dataTable, setDataTable] = useState([]);
    const [loading, setLoading] = useState(true);

    const { enqueueSnackbar } = useSnackbar();

    const buildHierarchy = (flatData) => {
        const dataMap = {};
        const result = [];

        if (data.module === 'projects') {
            flatData.forEach(item => {
                const parsedPrice = parseFloat(item.precio.replace('$', '').replace(',', '')) || 0;
                dataMap[item.id] = { ...item, cat: [], art: item.art || [], precio: parsedPrice };
            });

            flatData.forEach(item => {
                if (item.parent_id === null || item.parent_id === 'null') {
                    result.push(dataMap[item.id]);
                } else if (dataMap[item.parent_id]) {
                    dataMap[item.parent_id].cat.push(dataMap[item.id]);
                } else {
                    console.warn(`Nodo con ID ${item.id} tiene un parent_id (${item.parent_id}) no válido.`);
                }
            });

            const calculateTotals = (node) => {
                let total = 0;

                if (node.art && node.art.length > 0) {
                    total += node.art.reduce((sum, art) => {
                        const artPrice = parseFloat(art.precio.replace('$', '').replace(',', '')) || 0;
                        return sum + artPrice;
                    }, 0);
                }

                if (node.cat && node.cat.length > 0) {
                    total += node.cat.reduce((sum, subCategory) => sum + calculateTotals(subCategory), 0);
                }

                node.precio = total;
                return total;
            };

            result.forEach(rootNode => calculateTotals(rootNode));
        } else {
            flatData.forEach(item => {
                dataMap[item.id] = { ...item, cat: [], art: item.art || [] };
            });

            flatData.forEach(item => {
                if (item.parent_id === null || item.parent_id === 'null') {
                    result.push(dataMap[item.id]);
                } else if (dataMap[item.parent_id]) {
                    dataMap[item.parent_id].art.push(dataMap[item.id]);
                } else {
                    console.warn(`Nodo con ID ${item.id} tiene un parent_id (${item.parent_id}) no válido.`);
                }
            });
        }
        return result;
    };

    useEffect(() => {
        const fetchDataTable = async () => {
            try {
                const response = await getDataArray(`${config.API_BASE_URL}/${data.contentList}`, { proyID: data.id });
                const porcentajeGlobal = configData ? configData.porcentajeGlobal : 0;

                const formattedData = response.map(row => {
                    const { precioVentaValor, precioVentaFormatted, isPrecioVentaMenor } = calculatePrecioVenta(
                        row.precio_compra,
                        row.precio_venta,
                        porcentajeGlobal
                    );

                    return {
                        ...row,
                        precio_compra: formatCurrency(row.precio_compra),
                        precio_venta: formatCurrency(precioVentaValor),
                        isPrecioVentaMenor
                    };
                });

                const hierarchicalData = buildHierarchy(formattedData);
                setDataTable(hierarchicalData);

                const total = hierarchicalData.reduce((sum, category) => sum + category.precio, 0);
                if (onTotalCalculated) {
                    onTotalCalculated(total);
                }

                //console.log('REGISTROS CATEGORIAS: ' + JSON.stringify(dataTable, null, 2));
            } catch (error) {
                console.error('Error fetching categories:', error);
                enqueueSnackbar('Error al cargar las categorías', { variant: 'error' });
            }
        };

        fetchDataTable();
    }, [data.id, data.getData]);

    return (
        <TableContainer component={Paper}>
            <Table>
                <TableHead>
                    <TableRow>
                        {data.tableHeader.map((header, index) => (
                            <TableCell
                                key={index}
                                sx={{
                                    backgroundColor: '#052c65',
                                    color: 'white',
                                    width: header.width,
                                    textAlign: header.field === 'total' ? 'right' : header.align || 'center',
                                }}
                            >
                                {header.headerName}
                            </TableCell>
                        ))}
                    </TableRow>
                </TableHead>
                <TableBody>
                    {dataTable.length > 0 ? (
                        dataTable.map((row, index) => (
                            <ExpandableData key={index} data={row} configData={data} level={0} />
                        ))
                    ) : (
                        <TableRow>
                            <TableCell colSpan={data.tableHeader ? data.tableHeader.length : 1} align="center">
                                No hay datos para mostrar.
                            </TableCell>
                        </TableRow>
                    )}
                </TableBody>
            </Table>
        </TableContainer>
    );
};

const ExpandableData = ({ data, configData, level = 0 }) => {
    const { config } = UseAppConfig();
    const [isExpanded, setIsExpanded] = useState(false);

    const { enqueueSnackbar } = useSnackbar();
    const navigate = useNavigate();

    const [isAddingSubcategory, setIsAddingSubcategory] = useState(false);
    const [newSubcategoryName, setNewSubcategoryName] = useState('');

    const [modalOpen, setModalOpen] = useState(false);
    const [modalSection, setModalSection] = useState(false);

    const [OpenConfirm, setConfirmOpen] = useState(false);
    const [ConfirmRcdID, setConfirmRcdID] = useState('');
    const [ConfirmValue, setConfirmValue] = useState('');
    const [RcdName, setRcdName] = useState('');

    const commonCellStyle = {
        textAlign: 'center',
        width: '10%',
    };

    const handleToggle = () => {
        setIsExpanded(!isExpanded);
    };

    const [sectionFormValues, setSectionFormValues] = useState({
        proyID: configData.id,
        secTitle: newSubcategoryName,
        secDesc: '',
        categoria_padre_id: data.id
    });

    const ColorsDefault = ({ level, nombre, tipo }) => {
        const styleConfig = {
            'Categoría': { color: 'black', fontSize: '15px', fontWeight: level === 0 ? 'bold' : level === 1 ? 'normal' : 'normal', textTransform: 'uppercase' },
            'Compuesto': { color: 'green', fontSize: '15px', fontWeight: 'normal' },
            'Simple': { color: 'blue', fontSize: '14px', fontWeight: 'normal' },
        };

        const defaultStyle = { color: 'red', fontSize: '14px', fontWeight: 'normal' };
        const appliedStyle = styleConfig[tipo] || defaultStyle;

        return <span style={appliedStyle}>{nombre}</span>;
    };

    const botonesConfig = {
        articles: {
            'Compuesto': [
                { name: '', label: 'Eliminar', color: 'warning', icon: faEdit, imagen: '' },
            ],
            'Simple': [
                { name: '', label: 'Eliminar', color: 'warning', icon: faEdit, imagen: '' },
            ],
        },
        projects: {
            'Categoría': [
                { name: 'subCat', label: 'Agregar Sub-Categoría', color: 'success', icon: faBox, imagen: 'openBox.png' },
                { name: 'editCat', label: 'Editar', color: 'warning', icon: faEdit, imagen: '' },
                { name: 'addArt', label: 'Agregar Artículos', color: 'info', icon: faCopy, imagen: 'QR.png' },
                { name: 'delCat', label: 'Eliminar', color: 'error', icon: faTrash, imagen: 'openBox.png' },
            ],
            'Compuesto': [
                { name: 'delArt', label: 'Eliminar Artículo Compuesto', color: 'error', icon: faTrash, imagen: 'QR.png  ' },
            ],
            'Simple': [
                { name: 'delArt', label: 'Eliminar Artículo Simple', color: 'error', icon: faTrash, imagen: 'barCode.png' },
            ],
        },
    };

    const botones = botonesConfig[configData?.module]?.[data?.tipo] || [];

    const handleRcdEdit = async (thisType, thisID, thisName) => {
        switch (configData.module) {
            case 'articles':
                if (data.tipo === 'Simple') {
                    navigate(`details/${thisID}`);
                } else {
                    navigate(`/compounds/details/${thisID}`);
                }
                break;
            case 'projects':
                switch (thisType) {
                    case 'subCat':
                        setIsAddingSubcategory(true);
                        break;
                    case 'editCat':
                        setModalSection('categories');
                        setModalOpen(true);
                        break;
                    case 'addArt':
                        setModalSection('articles');
                        setModalOpen(true);
                        break;
                    case 'delCat':
                        handleConfirmsOpen({ id: thisID, ConfirmValue: 2, RcdName: thisName });
                        break;
                    case 'delArt':
                        handleConfirmsOpen({ id: thisID, ConfirmValue: 3, RcdName: thisName });
                        break;
                    default:
                        console.warn('Tipo no reconocido:', thisType);
                        break;
                }
                break;
        }
    };

    const renderModalContent = () => {
        switch (modalSection) {
            case 'categories':
                return <EditSection data={data.id} />;
            case 'articles':
                return <AddArticles proyID={configData.id} catID={data.id} catName={data.nombre} setModalOpen={setModalOpen} />;;
            default:
                return null;
        }
    };

    const handleModalClose = () => {
        setModalOpen(false);
    };

    const handleConfirmsOpen = (params) => {
        setConfirmRcdID(params.id);
        setRcdName(params.RcdName);
        setConfirmValue(params.ConfirmValue);
        setConfirmOpen(true);
    };

    const handleConfirmClose = () => {
        setConfirmOpen(false);
    };

    const handleSaveSubcategory = async () => {
        if (newSubcategoryName.trim()) {
            setIsAddingSubcategory(false);
            const updatedValues = {
                ...sectionFormValues,
                secTitle: newSubcategoryName,
            };
            //console.log('LOS NUEVOS DATOS SON: ', JSON.stringify(updatedValues, null, 2));
            await onAddSubcategory(updatedValues);
        }
    };

    const handleConfirm = async () => {
        try {
            switch (ConfirmValue) {
                case 2:
                    var url = `${config.API_BASE_URL}/retira/categoriaProyecto`;
                    var params = { proyID: configData.id, catID: ConfirmRcdID };
                    break;
                case 3:
                    var url = `${config.API_BASE_URL}/retira/articuloCategoria`;
                    var params = { id: ConfirmRcdID };
                    break;
            }
            const result = await sendPostData(url, params);
            enqueueSnackbar('Estado del Artículo ha sido retirado exitosamente' + ConfirmValue, { variant: 'success' });
            setConfirmOpen(false);
            window.location.reload();
        } catch (error) {
            enqueueSnackbar('Error al intentar retirar este artículo', { variant: 'error' });
            setConfirmOpen(false);
        }
        console.log('Confirmado');
    };

    const onAddSubcategory = async (values) => {
        try {
            const result = await sendPostData(`${config.API_BASE_URL}/crea/categoria`, values);
            enqueueSnackbar('Sección Creada Exitosamente', { variant: 'success' });
            window.location.reload();
        } catch (error) {
            console.error('Error al crear la subcategoría:', error);
            enqueueSnackbar('Ha ocurrido un error al intentar crear la nueva sección', { variant: 'error' });
        }
    };

    useEffect(() => {
        setSectionFormValues((prevValues) => ({
            ...prevValues,
            secTitle: newSubcategoryName,
        }));
    }, [newSubcategoryName]);

    const handleCancelAddSubcategory = () => {
        setIsAddingSubcategory(false);
        setNewSubcategoryName('');
    };

    return (
        <>
            {data && (
                <TableRow>
                    <TableCell
                        sx={{
                            borderBottom: 'none',
                            position: 'relative',
                            backgroundColor: '#f5f5f5',
                            paddingLeft: `${level * 2}%`,
                            width: '20%'
                        }}
                    >
                        <Box sx={{ display: 'flex', alignItems: 'center', height: 15 }}>
                            <Typography sx={{ display: 'flex', alignItems: 'center', fontSize: '14px' }}>
                                <Box component="span"
                                    sx={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        marginLeft: level === 0 ? 0 : 0
                                    }}>
                                    <IconsDefault tipo={data.tipo} icon={data.icono} />
                                    <span style={{ marginLeft: '12px', marginRight: '1px', fontSize: '20px' }}></span>
                                    <ColorsDefault level={level} nombre={data.nombre} tipo={data.tipo} />
                                </Box>
                            </Typography>
                            {(data.art && data.art.length > 0) || (data.cat && data.cat.length > 0) ? (
                                <IconButton
                                    sx={{
                                        marginLeft: -3,
                                        marginRight: -1,
                                        cursor: 'pointer'
                                    }}
                                    onClick={handleToggle}
                                >
                                    {isExpanded ? <ExpandLess /> : <ExpandMore />}
                                </IconButton>
                            ) : (
                                <span
                                    style={{
                                        marginLeft: level === 0 ? '10px' : level === 1 ? '-10px' : '-5px',
                                        marginRight: '15px',
                                        fontSize: '20px'
                                    }}
                                >
                                    &nbsp;
                                </span>
                            )}
                        </Box>
                    </TableCell>

                    {Object.keys(data)
                        .filter((field) =>
                            !(configData.module === 'projects'
                                ? [
                                    'id',
                                    'parent_id',
                                    'nombre',
                                    'tipo',
                                    'icono',
                                    'status',
                                    'cat',
                                    'art',
                                    'precio_compra',
                                    'precio_venta',
                                    'isPrecioVentaMenor'
                                ]
                                : [
                                    'id',
                                    'parent_id',
                                    'nombre',
                                    'tipo',
                                    'icono',
                                    'cat',
                                    'art',
                                    'isPrecioVentaMenor'
                                ]
                            ).includes(field)
                        )
                        .map((field, index) => (
                            <TableCell
                                key={`cell-${index}-${field}`}
                                sx={{
                                    ...commonCellStyle,
                                    textAlign: index === 1 ? 'left' : 'center',
                                    '&.red-text': field === 'precio_venta' && data.isPrecioVentaMenor,
                                }}
                                className={field === 'precio_venta' && data.isPrecioVentaMenor ? 'red-text' : ''}
                            >
                                {field === 'precio' && data.tipo === 'Categoría'
                                    ? formatCurrency(data[field])
                                    : (data[field] || `Sin ${field}`)}
                            </TableCell>
                        ))}

                    <TableCell sx={{ textAlign: 'center', width: '10%' }}>
                        {botones.map((button, index) => (
                            <Button
                                key={index}
                                variant="contained"
                                color={button.color}
                                size="small"
                                title={button.label}
                                onClick={() => handleRcdEdit(button.name, data.id, data.nombre)}
                                style={{
                                    minWidth: '30px',
                                    height: '30px',
                                    margin: '2px',
                                    borderRadius: '0px',
                                }}
                            >
                                {button.imagen ? (
                                    <img
                                        src={`/assets/icons/${button.imagen}`}
                                        width="15px"
                                        alt={button.label}
                                        style={{ bottom: '-1px', position: 'relative' }}
                                    />
                                ) : (
                                    <FontAwesomeIcon icon={button.icon} />
                                )}
                            </Button>
                        ))}
                    </TableCell>
                </TableRow >
            )}

            {
                isAddingSubcategory && (
                    <TableRow sx={{ '& > *': { padding: '4px 8px' } }}>
                        <TableCell colSpan={10} sx={{ paddingLeft: `${(level + 1) * 2.5}%` }}>
                            <Typography>Agregar Nueva Sub Categoría:</Typography>
                            <Box sx={{ display: 'flex', alignItems: 'center', marginTop: 1 }}>
                                <TextField
                                    size="small"
                                    label="Nombre de la Subcategoría"
                                    placeholder="Nombre de la subcategoría"
                                    variant="outlined"
                                    value={newSubcategoryName}
                                    onChange={(e) => setNewSubcategoryName(e.target.value)}
                                    sx={{ marginRight: 1, flexGrow: 1 }}
                                />
                                <Button
                                    variant="contained"
                                    color="primary"
                                    size="small"
                                    onClick={handleSaveSubcategory}
                                    startIcon={<Save />}
                                >
                                    Guardar
                                </Button>
                                <Button
                                    variant="contained"
                                    color="error"
                                    size="small"
                                    onClick={handleCancelAddSubcategory}
                                    startIcon={<Cancel />}
                                    sx={{ marginLeft: 1 }}
                                >
                                    Cancelar
                                </Button>
                            </Box>
                        </TableCell>
                    </TableRow>
                )
            }

            {
                data.cat && (
                    <TableRow>
                        <TableCell style={{ padding: 0, borderBottom: 'none' }} colSpan={10}>
                            <Collapse in={isExpanded} timeout="auto" unmountOnExit>
                                <Table size="small">
                                    <TableBody>
                                        {data.cat.map((child, index) => (
                                            <ExpandableData key={index} data={child} configData={configData} level={level + 1} />
                                        ))}
                                    </TableBody>
                                </Table>
                            </Collapse>
                        </TableCell>
                    </TableRow>
                )
            }

            {
                data.art && (
                    <TableRow>
                        <TableCell style={{ padding: 0, borderBottom: 'none' }} colSpan={10}>
                            <Collapse in={isExpanded} timeout="auto" unmountOnExit>
                                <Table size="small">
                                    <TableBody>
                                        {data.art.map((child, index) => (
                                            <ExpandableData key={index} data={child} configData={configData} level={level + 1} />
                                        ))}
                                    </TableBody>
                                </Table>
                            </Collapse>
                        </TableCell>
                    </TableRow>
                )
            }

            <Modal
                open={modalOpen}
                onClose={handleModalClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                }}
            >
                <Paper
                    elevation={3}
                    sx={{
                        minWidth: 700,
                        padding: 3,
                        backgroundColor: '#f5f5f5',
                        borderRadius: 2
                    }}
                >
                    {renderModalContent()}
                </Paper>
            </Modal>
            <ConfirmationDialog
                open={OpenConfirm}
                onClose={handleConfirmClose}
                onConfirm={handleConfirm}
                statusValue={ConfirmValue}
                keyValue={RcdName}
            />
        </>
    );
}