export const articlesHeaders = [
	{
		"field": "clave",
		"headerName": "Clave del Producto o Servicio",
		"headerAlign": "center",
		"align": "center",
		"width": 400
	},
	{
		"field": "descCorta",
		"headerName": "Descripción",
		"headerAlign": "center",
		"align": "center",
		"width": '10%'
	},
	{
		"field": "familia",
		"headerName": "Familia",
		"headerAlign": "center",
		"align": "center",
		"width": '10%'
	},
	{
		"field": "unidad",
		"headerName": "Unidad",
		"headerAlign": "center",
		"align": "center",
		"width": 100
	},
	{
		"field": "precioCompra",
		"headerName": "Precio de Compra",
		"headerAli	gn": "center",
		"align": "center",
		"currency": 1,
		"width": 100
	},
	{
		"field": "cantidad",
		"headerName": "Cantidad",
		"headerAlign": "center",
		"align": "center",
		"currency": 1,
		"width": 100
	},
	{
		"field": "precioVenta",
		"headerName": "Precio de Venta",
		"headerAlign": "center",
		"align": "center",
		"currency": 1,
		"width": 100
	},
	{
		"field": "status",
		"headerName": "Activo",
		"headerAlign": "center",
		"align": "center",
		"width": 50,
		"botons": {
			"estado": {
				"label": "estado",
				"icon": "edit",
				"class": "primary",
				"action": ""
			}
		}
	},
	{
		"field": "acciones",
		"headerName": "Acciones",
		"headerAlign": "center",
		"align": "center",
		"width": 20,
		"botons": {
			"editar": {
				"label": "Editar",
				"icon": "edit",
				"class": "warning",
				"type": "link",
				"action": "details/${row.id}"
			}
		}
	}
];
